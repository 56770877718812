import React from 'react';
import PropTypes from 'prop-types';

const TimeToRead = ({ timeToRead }) => (
  <span>
    {timeToRead} min{timeToRead === 1 ? '' : 's'} to read
  </span>
);

TimeToRead.propTypes = {
  timeToRead: PropTypes.number.isRequired,
};

export default TimeToRead;
