import React from 'react';

import { graphql } from 'gatsby';

import BlogPost from '../components/BlogPost';
import SiteMetadataContext from '../containers/SiteMetadataContext';
import SiteTitle from '../containers/SiteTitle';
import Layout from '../layouts';

function BlogPostTemplate(props) {
  const post = props.data.markdownRemark;
  const { previous, next } = props.pageContext;
  const location = props.location;

  return (
    <Layout location={location}>
      <SiteTitle title={post.frontmatter.title} />
      <SiteMetadataContext.Consumer>
        {({ disqusShortname }) => (
          <BlogPost
            title={post.frontmatter.title}
            date={post.frontmatter.date}
            html={post.html}
            timeToRead={post.timeToRead}
            previous={
              previous && {
                path: previous.fields.slug,
                title: previous.frontmatter.title,
              }
            }
            next={
              next && {
                path: next.fields.slug,
                title: next.frontmatter.title,
              }
            }
            tags={post.frontmatter.tags}
            author={post.frontmatter.author}
            disqusShortname={disqusShortname}
          />
        )}
      </SiteMetadataContext.Consumer>
    </Layout>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        author {
          id
          name
          title
          avatar {
            childImageSharp {
              fixed(width: 64, height: 64, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
      timeToRead
    }
  }
`;
