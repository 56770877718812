/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import DisqusComments from 'react-disqus-comments';

import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { Link } from 'gatsby';

import { rhythm, scale } from '../utils/typography';
import TagLink from './TagLink';
import TimeToRead from './TimeToRead';

const PostTitle = styled('h1')({
  ...scale(1.5),
});

const PostBody = styled('div')`
  a {
    text-decoration: underline;
  }
`;

const PostFooter = styled('footer')``;

const PostFooterNavigation = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;

  a {
    text-decoration: none;
  }
`;

const PostFooterNavigationItem = styled('li')`
  ${css(scale(1 / 5))} flex: 1 1 50%;
  justify-content: space-between;
  align-items: center;

  margin: 0;
`;

const PostFooterNavigationItemIcon = styled('i')`
  font-size: 3rem;
  vertical-align: sub;
`;

const PostTags = styled('ul')`
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
  }

  li + li {
    margin-left: 3px;
  }
`;

const Info = styled('p')`
  ${css(scale(-1 / 4))}
  margin: ${rhythm(1 / 2)} 0;
  text-align: center;
`;

const Comments = styled('div')``;

const BlogPost = ({
  title,
  date,
  timeToRead,
  html,
  previous,
  next,
  tags,
  disqusShortname,
  theme,
}) => (
  <article>
    <PostTitle>{title}</PostTitle>
    <Info>
      {date} &middot; <TimeToRead timeToRead={timeToRead} />
    </Info>

    <PostBody dangerouslySetInnerHTML={{ __html: html }} />
    <PostFooter>
      {tags && (
        <PostTags>
          {tags.map(tag => (
            <li key={tag}>
              <TagLink tag={tag} />
            </li>
          ))}
        </PostTags>
      )}
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <PostFooterNavigation>
        {previous && (
          <PostFooterNavigationItem
            css={css`
              text-align: left;
            `}
          >
            <p>
              <PostFooterNavigationItemIcon>←</PostFooterNavigationItemIcon>
              Previous Post
            </p>
            <Link to={previous.path} rel="prev">
              {previous.title}
            </Link>
          </PostFooterNavigationItem>
        )}
        {next && (
          <PostFooterNavigationItem
            css={css`
              text-align: right;
            `}
          >
            <p>
              Next Post
              <PostFooterNavigationItemIcon>→</PostFooterNavigationItemIcon>
            </p>
            <Link to={next.path} rel="next">
              {next.title}
            </Link>
          </PostFooterNavigationItem>
        )}
      </PostFooterNavigation>
      <Comments>
        {/* Reset Disqus when theme changes */}
        <DisqusComments key={theme.primaryColor} shortname={disqusShortname} />
      </Comments>
    </PostFooter>
  </article>
);

BlogPost.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
  html: PropTypes.string.isRequired,
  next: PropTypes.shape({
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  previous: PropTypes.shape({
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  tags: PropTypes.arrayOf(PropTypes.string),
  author: PropTypes.object.isRequired,
  disqusShortname: PropTypes.string.isRequired,
};

export default withTheme(BlogPost);
